@tailwind base;
@tailwind components;
@tailwind utilities;

/* Dark mode transitions */
html.dark {
    color-scheme: dark;
}

* {
    transition:
        background-color 0.3s ease,
        border-color 0.3s ease;
}

p {
    margin: 0;
}

/* Animation for theme toggle */
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
