/* Styling untuk Heading */
.markdown-container {
    font-size: 14px;
}

p {
    box-sizing: border-box !important;
}

::selection {
    color: #14ffe8;
    background-color: #0b746f;
}

.markdown-container h1,
.markdown-container h2,
.markdown-container h3,
.markdown-container h4,
.markdown-container h5,
.markdown-container h6 {
    font-weight: bold;
    color: white; /* Semua heading warna putih */
}

.markdown-container p {
    font-size: 14px;
}

/* Ukuran font untuk Heading */
.markdown-container h1 {
    font-size: 25px; /* Besar untuk h1 */
}

.markdown-container h2 {
    font-size: 20px; /* Besar untuk h2 */
}

.markdown-container h3 {
    font-size: 18px; /* Sedikit lebih kecil dari h2 */
}

.markdown-container h4 {
    font-size: 15px; /* Sedikit lebih kecil dari h3 */
}

.markdown-container h5 {
    font-size: 14px; /* Sedikit lebih kecil dari h4 */
}

.markdown-container h6 {
    font-size: 13px; /* Paling kecil untuk h6 */
}

/* Styling untuk teks kode inline */
.markdown-container code {
    background-color: #141a30;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 1rem;
}

/* Styling untuk gambar */
.markdown-container img {
    max-width: 100%;
    border-radius: 8px;
    margin-top: 10px;
}

/* Styling untuk link */
.markdown-container a {
    color: #61dafb;
    text-decoration: none;
}

.markdown-container a:hover {
    text-decoration: underline;
}

/* Styling untuk blockquote */
.markdown-container blockquote {
    border-left: 4px solid #242b44;
    padding-left: 16px;
    margin-left: 0;
    color: #bac3c8;
    font-style: italic;
}

ul,
ol {
    display: flex;
    flex-direction: column;
    gap: 1px;
    margin-left: 15px !important; /* Menghapus margin pada list */
}

ul {
    list-style: disc;
}
ol {
    font-size: 14px !important;
    list-style-type: decimal;
}

li p {
    margin-top: -22px !important; /* Menghapus margin default pada elemen <p> */
    padding: 0 !important; /* Menghapus padding default */
}

li {
    margin: 0.5rem 0; /* Memberikan jarak antar item */
}

/* Styling untuk list */
.markdown-container ul,
.markdown-container ol {
    padding-left: 15px; /* Kurangi padding kiri */
    margin-left: 0; /* Mengurangi margin kiri */
}

/* Styling untuk pre dan code block */
pre {
    display: inline-flex;
    flex-direction: column;
    gap: 0;
    padding: 0;
    margin: 0;
    border-radius: 5px;
    overflow-x: auto; /* Scroll hanya untuk horizontal */
    overflow-y: hidden;
    width: 100%; /* Ikuti lebar konten */
    height: auto; /* Ikuti tinggi konten */
    background-color: #282c34; /* Warna background utama */
    margin-bottom: 15px !important;
}

pre code {
    display: inline;
    height: auto;
    padding: 1px;
    margin: 0;
    font-size: 13px !important;
    font-family: "Fira Code", monospace;
    background-color: #2f3445;
}

table {
    width: 100%; /* Ubah lebar tabel agar sepenuhnya responsif */
    border-collapse: collapse;
    font-size: 14px;
    text-align: left;
    margin: 20px 0;
    table-layout: fixed; /* Pastikan kolom memiliki ukuran tetap */
    overflow-wrap: break-word; /* Mengatasi teks panjang */
    font-family: "Poppins", sans-serif;
}

.codes {
    padding: 4px !important;
}

.codehead {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #141a30;
    border: none;
    margin: 0 !important;
    height: 30px !important;
}

.codehead p {
    color: white;
}

.codehead button {
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 1px;
    width: auto !important;
}

.codehead button span {
    margin-left: 3px;
}

th,
td {
    padding: 12px 15px; /* Tambahkan padding untuk ruang lebih di sekitar teks */
    border: 0.5px solid #ccc;
    font-family: "Poppins", sans-serif;
}

th {
    font-weight: bold;
    background-color: #141a30; /* Warna latar belakang gelap untuk header tabel */
    color: #d4d4d4; /* Warna teks yang kontras untuk header */
    font-family: "Poppins", sans-serif;
}

td {
    background-color: #141a30; /* Warna latar belakang gelap untuk isi tabel */
    color: #d4d4d4; /* Warna teks isi tabel */
    overflow-wrap: break-word; /* Membungkus kata agar tidak keluar dari sel */
    word-break: break-all; /* Memastikan kata panjang terpotong dengan benar */
    font-family: "Poppins", sans-serif;
}
