.skeleton-loader {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: transparent;
    border-radius: 10px;
    animation: changeWidth 1s ease-in-out forwards; /* Tambahkan 'forwards' agar ukuran tetap */
    font-family: "Poppins", sans-serif;
    max-width: 400px;
}

.default-ctr {
    padding: 10px;
}

@keyframes changeWidth {
    0% {
        width: 10%;
    }
    100% {
        width: 100%;
    }
}

.skeleton-text {
    background-color: white;
    border-radius: 4px;
    animation: shimmer 2s infinite linear; /* Pastikan ini tetap infinite */
    font-family: "Poppins", sans-serif;
}

.skeleton-text {
    width: 100%;
    height: 15px;
}

.skeleton-text.short {
    width: 70%;
}
.skeleton-text.mid {
    width: 80%;
}

@keyframes shimmer {
    0% {
        background-position: -500px 0;
    }
    100% {
        background-position: 500px 0;
    }
}

.skeleton-loader .skeleton-text {
    background-image: linear-gradient(
        90deg,
        #23283a 25%,
        #3e4254 50%,
        #23283a 75%
    );
    background-size: 500px 100%;
}

.image-container {
    width: 400px;
    height: 400px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.loader {
    flex-grow: 1;
    width: auto;
    border-radius: 10px;
    background-image: linear-gradient(
        90deg,
        #23283a 25%,
        #3e4254 50%,
        #23283a 75%
    );
    background-size: 200% 100%;
    animation: shimmer2 5s infinite linear;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    z-index: 5;
}

.result-ctr .loader {
    width: 100% !important;
    height: 100% !important;
}

.image-container .result-ctr {
    flex-grow: 1;
    width: 150px;
    margin: 0;
    padding: 0;
}

.result-ctr .img-content {
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
}

.hidden {
    display: none !important;
}

.loader .indicator {
    width: 30px !important;
    animation: opacityAnim 4s infinite;
}

@keyframes shimmer2 {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}
@keyframes opacityAnim {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

.default-loader {
    width: 30px;
    aspect-ratio: 4;
    --_g: no-repeat radial-gradient(circle closest-side, #434652 90%, #0000);
    background:
        var(--_g) 0% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: calc(100% / 3) 100%;
    animation: l7 2s infinite linear;
}

@keyframes l7 {
    33% {
        background-size:
            calc(100% / 3) 0%,
            calc(100% / 3) 100%,
            calc(100% / 3) 100%;
    }
    50% {
        background-size:
            calc(100% / 3) 100%,
            calc(100% / 3) 0%,
            calc(100% / 3) 100%;
    }
    66% {
        background-size:
            calc(100% / 3) 100%,
            calc(100% / 3) 100%,
            calc(100% / 3) 0%;
    }
}
