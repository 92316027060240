.chat-user {
    background: #141a30;
    padding: 10px 15px;
    border-radius: 20px;
    min-width: 100px;
    max-width: 300px;
}

p {
    font-size: 14px !important;
    margin: 0 !important;
}

.chat-ai {
    width: 100% !important;
}

.user {
    font-size: 14px !important;
}

.image-wrapper {
    position: relative;
    width: 100%;
    max-height: 230px;
    border-radius: 10px;
    overflow: hidden;
}

.image-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8c8f97;
    background-image: linear-gradient(
        90deg,
        #23283a 25%,
        #3e4254 50%,
        #23283a 75%
    );
    background-size: 200% 100%;
    animation: shimmer 2s infinite linear;
    font-size: 30px;
    z-index: 10; /* Memastikan loader di atas gambar */
}

.image-content {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    display: block;
    position: relative;
    z-index: 5; /* Membuat gambar di bawah loader */
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}
