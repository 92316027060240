.closing {
    opacity: 0 !important;
}

.qClose {
    margin-top: 250px !important;
}

.QLclose {
    width: 120px !important;
}

#QLogo {
    width: 100px;
    transition: all 0.2s ease;
}

.question-box {
    z-index: 5;
    transition: all 0.2s ease;
    margin-top: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    gap: 10px;
    font-family: "Poppins", sans-serif;
}

.random-question {
    position: relative;
    top: 5px;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    width: 100%;
    font-family: "Poppins", sans-serif;
    scroll-behavior: auto;
}

.random-question::-webkit-scrollbar {
    display: none;
}

h2 {
    font-size: 24px !important;
    margin-bottom: 10px;
    font-weight: bold;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 3px;
    white-space: nowrap;
}

.st,
.nd,
.th {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 50px;
    white-space: nowrap;
}

.question-item {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    padding: 18px 8px !important;
    background: #141a30;
    border-radius: 50px;
    color: #555b6f;
    height: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-family: "Poppins", sans-serif;
}

@media (width >= 600px) {
    .question-box {
        margin-top: 200px;
    }

    .qClose {
        margin-top: 190px !important;
    }
}

@media (width <= 400px) {
    .question-box {
        margin-top: 190px;
    }

    .qClose {
        margin-top: 180px !important;
    }
}
