.btn-send {
    width: 40px !important;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: #222739 !important;
    color: #383956 !important;
}

.btn-file {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50px;
}

.pro-check {
    background-color: #222739;
    border: 1px solid #2e2e31 !important;
}

.pro-mode {
    border-left: 1px solid #2e2e31 !important;
}

.pro-label {
    color: #b6b6bd;
    font-size: 14px;
}

.chat-input {
    width: 100% !important;
    background-color: #131a2f !important;
    border: 1px solid #17243a !important;
}

textarea {
    font-size: 14px !important;
    color: white !important;
    field-sizing: content;
    min-height: 40px;
    max-height: 100px;
    padding-top: 11px !important;
}

textarea::-webkit-scrollbar {
    display: none;
}

textarea::placeholder {
    text-align: left;
    line-height: 1.1;
}

.check-active {
    background-color: #3557cd;
}

.input-container {
    /* z-index: 10; */
    background-color: #0f0d1f;
    padding-top: 0 !important;
}

.send-active {
    color: #3557cd !important;
    background-color: #2c3a6b !important;
}

@media (max-width: 1024px) {
    textarea {
        min-width: 450px !important;
        max-width: 450px !important;
    }
}
@media (max-width: 640px) {
    textarea {
        min-width: 280px !important;
        max-width: 280px !important;
    }
}
